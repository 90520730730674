import React, { FC, useCallback } from 'react';
import { Tag, Tile } from 'carbon-components-react';

interface BarcodesTileProps {
  barcodes: string[];
  onBarcodeClick: (barcode: string) => void;
}

interface BarcodeTagProps extends Omit<BarcodesTileProps, 'barcodes'> {
  barcode: string;
}

const BarcodeTag: FC<BarcodeTagProps> = ({ barcode, onBarcodeClick }) => {
  const onClick = useCallback(() => onBarcodeClick(barcode), [onBarcodeClick, barcode]);
  return (
    <Tag size="md" type="outline" title="Clear filter" onClick={onClick}>
      {barcode}
    </Tag>
  );
};

export const BarcodesTile: FC<BarcodesTileProps> = ({ barcodes, onBarcodeClick }) => {
  return (
    <Tile className="barcode-tile">
      <h3>UPCs</h3>
      {barcodes?.map?.((upc: string) => (
        <BarcodeTag barcode={upc} onBarcodeClick={onBarcodeClick} />
      ))}
    </Tile>
  );
};
