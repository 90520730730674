import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Loading } from 'carbon-components-react';

import { reduxStore, persistStore } from 'src/utilities';

export const ReduxProvider: FC = ({ children }) => (
  <Provider store={reduxStore}>
    <PersistGate persistor={persistStore} loading={<Loading />}>
      {children}
    </PersistGate>
  </Provider>
);
