import React, { FC } from 'react';
import { Tile } from 'carbon-components-react';
import { H4 } from 'src/components';

interface SimpleInfoTileProps {
  title: string;
  subtitle?: string | number;
  loading?: boolean;
}

export const SimpleInfoTile: FC<SimpleInfoTileProps> = ({ title, subtitle, loading }) => {
  return (
    <Tile className="simple-info-tile">
      <p>{title}</p>
      <H4 loading={loading} showIfChildrenExists>
        {subtitle ?? '--'}
      </H4>
    </Tile>
  );
};
