import { createSelector } from '@reduxjs/toolkit';
import { cond, constant, defaultTo, get, identity, pipe, split } from 'lodash/fp';
import { RootState } from '../types';
import { Buffer } from 'buffer';

const _getAppState = (state: RootState) => state.app;

export const getAppState = createSelector([_getAppState], (x) => x);

export const getIsLoggedIn = createSelector([getAppState], ({ clientId, accessToken, ...rest }) => {
  return !!clientId && !!accessToken;
});

interface ParsedAccessToken {
  clientId: number;
  dbVersion: number;
  username: string;
  userId: number;
}

const _parseAccessToken = createSelector<[typeof getAppState], ParsedAccessToken>(
  [getAppState],
  pipe(
    get('accessToken'),
    defaultTo(''),
    split('.'),
    get('[1]'),
    cond([
      [identity, (x) => Buffer.from(x, 'base64').toString()],
      [constant(true), constant('')],
    ]),
    (x: string) => {
      try {
        return JSON.parse(x);
      } catch (err) {
        return {};
      }
    },
    ({ dbVersion, sub, userId, clientId }) =>
      ({
        clientId: +clientId,
        username: sub,
        dbVersion: +dbVersion,
        userId: +userId,
      } as ParsedAccessToken)
  )
);

export const getUsername = createSelector([_parseAccessToken], (x) => x.username);
export const getDBVersion = createSelector([_parseAccessToken], (x) => x.dbVersion);
