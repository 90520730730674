import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

import { inflateLink } from './_inflate-link';
import { addHeadersLink } from './_add-headers-link';
import { httpLink } from './_http-link';
import { retryLink } from './_retry-link';
import { onErrorLink } from './_on-error-link';

export const apolloClient = new ApolloClient({
  link: from([retryLink, onErrorLink, addHeadersLink, inflateLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      UserToYdItemsNode: {
        fields: {
          nodes: offsetLimitPagination(),
        },
      },
    },
  }),
  version: process.env.REACT_APP_VERSION,
  name: process.env.npm_package_name,
});
