import { useQuery, gql } from '@apollo/client';

interface Variables {
  id: string;
  storeLimit: number;
  storeOffset?: number;
}

const query = gql`
  query itemDetail($id: GUID!, $storeLimit: Int!, $storeOffset: Int) {
    currencyCode
    labels {
      dimension1
      dimension2
      dimension3
      dimension4
      dimension5
      dimension6
      dimension7
      dimension8
      dimension9
      dimension10
      store
      vendor
    }
    me {
      id
      ydItemById(id: $id) {
        code
        upcs
        description
        totalOnHand
        totalOnOrder
        nextExpectedDelivery
        defaultRetailPrice
        primaryImageURL
        dimension1
        dimension2
        dimension3
        dimension4
        dimension5
        dimension6
        dimension7
        dimension8
        dimension9
        dimension10
        primaryVendor {
          name
        }
        level1 {
          description
        }
        level2 {
          description
        }
        level3 {
          description
        }
        level4 {
          description
        }
        stores {
          id
          count
          nodes(limit: $storeLimit, offset: $storeOffset, order: { name: placement }) {
            id
            store {
              id
              description
            }
            onHand
            bin
          }
        }
        photos(limit: 10, offset: 0, order: { name: placement }) {
          id
          url
        }
        matrix {
          id
          items(
            limit: 100
            offset: 0
            order: { name: dimension1, nextOrderBy: { name: dimension2 } }
          ) {
            id
            description
            dimension1
            dimension2
            primaryImageURL
            totalOnHand
          }
        }
      }
    }
  }
`;

export const UseDingoGetItemDetailsStoreLimit = 10;

export const useDingoGetItemDetails = (itemId: string) => {
  return useQuery<any, Variables>(query, {
    fetchPolicy: 'cache-first',
    variables: { id: itemId, storeLimit: UseDingoGetItemDetailsStoreLimit },
  });
};
