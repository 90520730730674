import { RetryLink } from '@apollo/client/link/retry';

export const retryLink = new RetryLink({
  delay: {
    initial: 100,
    jitter: true,
  },
  attempts: {
    max: 10,
    retryIf: (err, operation) => !!err && operation.operationName !== 'login',
  },
});
