import React, { FC, useCallback, useMemo } from 'react';
import {
  Tile,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableHeader,
  TableCell,
  DataTableHeader,
  TableRowProps,
} from 'carbon-components-react';
import { ChevronRight20 } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import _, { tail } from 'lodash';
import { Image, P } from 'src/components';

interface Item {
  id: string;
  description: string;
  code: string;
  dimension1?: string | null;
  dimension2?: string | null;
  primaryImageURL?: string | null;
  totalOnHand?: number | null;
}

type Nullable<T> = { [K in keyof T]: T[K] | null };

interface NullableItem extends Nullable<Item> {
  id: string;
}

interface MatrixTileProps {
  items?: Item[];
  loading?: boolean;
}

const defaultRows = _.range(10).map(
  (x) =>
    ({
      id: `${x}`,
      description: null,
      code: null,
      dimension1: null,
      dimension2: null,
      primaryImageURL: null,
    } as NullableItem)
);

const headers: DataTableHeader[] = [
  {
    key: 'primaryImageURL',
    header: '',
  },
  {
    key: 'dimension1',
    header: 'Size',
  },
  {
    key: 'dimension2',
    header: 'Color',
  },
  {
    key: 'totalOnHand',
    header: 'Total',
  },
];

interface MatrixRowProps {
  tableRowProps?: TableRowProps;
  imgURL?: string | null;
  loading?: boolean;
  id?: string;
  cells?: { id: any; value: any }[];
}

const MatrixRow: FC<MatrixRowProps> = ({ tableRowProps, imgURL, loading, id, cells }) => {
  const navigate = useNavigate();
  const navigateToItem = useCallback(() => navigate(`/items/${id}`), [id, navigate]);
  return (
    <TableRow {...(tableRowProps ?? {})} onClick={navigateToItem}>
      <TableCell width={20}>
        <Image src={imgURL} alt="matrix item" height={20} width={20} className="rounded" />
      </TableCell>
      {cells?.map?.((cell: any) => (
        <TableCell key={cell.id} align="left">
          <P loading={loading} showIfChildrenExists>
            {cell.value}
          </P>
        </TableCell>
      ))}
      <TableCell align="right">
        <ChevronRight20 />
      </TableCell>
    </TableRow>
  );
};

export const MatrixTile: FC<MatrixTileProps> = ({ items, loading }) => {
  const rows = useMemo(() => {
    if (!items && loading) {
      return defaultRows;
    }
    return items ?? [];
  }, [items, loading]);
  return (
    <Tile>
      <DataTable
        rows={rows as any}
        headers={headers}
        size="xs"
        isSortable
        useZebraStyles
        overflowMenuOnHover
      >
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }: any) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header: any) => (
                  <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                ))}
                <TableHeader className="image-col"></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <MatrixRow
                  tableRowProps={getRowProps({ row })}
                  imgURL={row.cells[0].value}
                  id={row.id}
                  loading={loading}
                  cells={tail(row.cells)}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </DataTable>
    </Tile>
  );
};
