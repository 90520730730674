import { useQuery, gql } from '@apollo/client';

const query = gql`
  query GetItems($limit: Int!, $offset: Int!, $order: YdItemOrderBy, $searchText: String) {
    labels {
      dimension1
      dimension2
      dimension3
      vendor
    }
    me {
      id
      ydItems(searchText: $searchText) {
        count
        id
        nodes(limit: $limit, offset: $offset, order: $order) {
          description
          id
          dimension1
          dimension2
          dimension3
          code
          primaryImageURL
          totalOnHand
          primaryVendor {
            name
          }
        }
      }
    }
  }
`;

export const useDingoGetItems = (searchText?: string) => {
  return useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 50,
      offset: 0,
      searchText,
      order: {
        direction: 'asc',
        name: 'description',
        nextOrderBy: {
          name: 'dimension1',
          nextOrderBy: {
            name: 'dimension2',
          },
        },
      },
    },
  });
};
