import React, { CSSProperties, FC, useEffect, useRef } from 'react';
//@ts-ignore
import JsBarcode, { JsBarcodeOptions } from 'jsbarcode';

interface BarcodeProps {
  value: string;
  style?: CSSProperties;
  className?: string;
  options?: JsBarcodeOptions;
}

export const Barcode: FC<BarcodeProps> = ({ value, style, className, options }) => {
  const containerRef = useRef<SVGSVGElement>(null);
  useEffect(() => {
    JsBarcode(containerRef.current!, value, options);
  }, [value, options]);

  return <svg ref={containerRef} style={style} className={className} />;
};
