import React, {
  createContext,
  FC,
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal } from 'carbon-components-react';
import { batch } from 'react-redux';

interface LoginModalProps {}

interface LoginModalRef {
  open: (url: string) => void;
  close: () => void;
}

const LoginModal = forwardRef<LoginModalRef, LoginModalProps>((props, ref) => {
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState<string | null>(null);

  const open = useCallback(
    (url: string) => {
      batch(() => {
        setUrl(url);
        setShow(true);
      });
    },
    [setShow, setUrl]
  );

  const close = useCallback(() => setShow(false), [setShow]);
  const init = useCallback(() => ({ open, close } as LoginModalRef), [open, close]);
  useImperativeHandle(ref, init);

  const iframeRef = useRef<HTMLIFrameElement>();
  const setIframeRef = useCallback((ref) => {
    iframeRef.current = ref;
  }, []);

  return (
    <Modal {...props} open={show} passiveModal onRequestClose={close}>
      <div className="iframe-container">
        <iframe src={url as string} title="YD Login" ref={setIframeRef} />
      </div>
    </Modal>
  );
});

const initialRef = { open: (url: string) => {}, close: () => {} };

const loginModalContext = createContext(initialRef);

export const LoginModalProvider: FC = ({ children }) => {
  const modalRef = useRef<LoginModalRef>();

  const setModalRef = useCallback((ref) => {
    modalRef.current = ref;
  }, []);

  const open = useCallback((url: string) => modalRef.current?.open?.(url), []);
  const close = useCallback(() => modalRef.current?.close?.(), []);
  const value = useMemo(() => ({ open, close }), [open, close]);

  return (
    <loginModalContext.Provider value={value}>
      <LoginModal ref={setModalRef} />
      {children}
    </loginModalContext.Provider>
  );
};

export const useLoginModal = () => useContext(loginModalContext);
