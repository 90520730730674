import { useState, useEffect, useMemo, useRef } from 'react';
import { mapValues, constant, toPairs, cloneDeep } from 'lodash/fp';

interface UseMediaQueryParams {
  [key: string]: string;
}

const DefaultQueries = Object.freeze({
  small: '(max-width: 599px)',
  medium: '(min-width: 600px) and (max-width: 1199px)',
  large: '(min-width: 1200px)',
});

interface UserMediaQueryReturnType {
  small: boolean;
  medium: boolean;
  large: boolean;
  [key: keyof UseMediaQueryParams]: boolean;
}

const initializeMatches = mapValues(constant(false)) as (p: any) => UserMediaQueryReturnType;

export const useMediaQuery = (queryParams: UseMediaQueryParams = {}) => {
  const queries = useMemo(
    () => ({
      ...DefaultQueries,
      ...queryParams,
    }),
    [queryParams]
  );
  const initialMatches = useMemo(() => initializeMatches(queries), [queries]);
  const [matches, setMatches] = useState<UserMediaQueryReturnType>(initialMatches);
  const matchesRef = useRef(matches);

  useEffect(() => {
    const listener = () => {
      let differs = false;
      let newMatches = cloneDeep(matchesRef.current);
      toPairs(queries).forEach(([key, query]) => {
        const media = window.matchMedia(query);
        if (media.matches !== newMatches[key]) {
          differs = true;
          newMatches[key] = media.matches;
        }
      });
      if (differs) {
        setMatches(newMatches);
        matchesRef.current = newMatches;
      }
    };
    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [queries]);

  return matches;
};
