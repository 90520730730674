import { createHttpLink } from '@apollo/client';
// export const uri = Object.freeze('https://dingo.yellowdogsoftware.com');
// export const uri = Object.freeze('http://localhost:4000');
// const uri = Object.freeze('https://dingo-dev.yellowdog.software');
const uri = process.env.REACT_APP_DINGO_URL ?? 'https://dingo.yellowdogsoftware.com';

export const httpLink = createHttpLink({
  uri,
  fetch,
});
