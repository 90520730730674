import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  SwitcherDivider,
  Button,
  Table,
  TableBody,
  TableRow,
  TableHeader,
  TableCell,
} from 'carbon-components-react';
import { useSelector } from 'react-redux';
import { User24, Logout16, ChevronRightGlyph } from '@carbon/icons-react';
import { SearchBar } from './search-bar';
import { appStateSelectors, useAuth, useMediaQuery } from 'src/utilities';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useHelpLinks } from 'src/utilities/use-help-links';
import { Copyright, MadeWithLove, Version } from 'src/components';
import './containers.scss';

interface HideableProps {
  hidden?: boolean;
}
const Hideable: FC<HideableProps> = memo(({ children, hidden }) =>
  hidden ? <></> : <>{children}</>
);

export const AppHeader = () => {
  const { isLoggedIn, logout } = useAuth();
  const { helpLinks } = useHelpLinks();
  const username = useSelector(appStateSelectors.getUsername);
  const dbVersion = useSelector(appStateSelectors.getDBVersion);
  const { clientId } = useSelector(appStateSelectors.getAppState);

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    if (isLoggedIn) {
      setExpanded(!expanded);
    }
  }, [expanded, setExpanded, isLoggedIn]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      if (location.pathname === '/login') {
        navigate('/search');
      }
    } else {
      setExpanded(false);
      navigate('/login');
    }
  }, [isLoggedIn, location.pathname, navigate]);

  const { small } = useMediaQuery();
  const prefix = useMemo(() => (small ? 'YD' : 'Yellow Dog'), [small]);

  return (
    <Hideable hidden={!isLoggedIn}>
      <Header>
        <HeaderName prefix={prefix}>
          <Link to="/">Bloodhound</Link>
        </HeaderName>
        <HeaderGlobalBar>
          <SearchBar id="search-1" labelText="Item Search" placeholder="Item Search" />
          <HeaderGlobalAction aria-label="User" onClick={toggleExpanded}>
            <User24 />
          </HeaderGlobalAction>
        </HeaderGlobalBar>
        <HeaderPanel aria-label="Header Panel" expanded={expanded}>
          <div>
            <h3>{username}</h3>
            <SwitcherDivider />
            <Table useZebraStyles size="xs">
              <TableBody>
                <TableRow>
                  <TableHeader>Log Out</TableHeader>
                  <TableCell>
                    <Button kind="danger--ghost" size="sm" renderIcon={Logout16} onClick={logout} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableHeader>Client ID</TableHeader>
                  <TableCell>{clientId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableHeader>YD Version</TableHeader>
                  <TableCell>{dbVersion}</TableCell>
                </TableRow>
                {helpLinks.map(({ key, value }) => (
                  <TableRow key={key}>
                    <TableHeader>{key}</TableHeader>
                    <TableCell>
                      <Button
                        renderIcon={ChevronRightGlyph}
                        size="sm"
                        kind="ghost"
                        href={value[0]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Version />
          <div className="made-with-love">
            <MadeWithLove />
            <Copyright />
          </div>
        </HeaderPanel>
      </Header>
    </Hideable>
  );
};
