import React, { FC, HTMLProps, memo } from 'react';
import { SkeletonText } from 'carbon-components-react';

interface TextProps<T> extends HTMLProps<T> {
  loading?: boolean;
  showIfChildrenExists?: boolean;
}

export const Text: FC<TextProps<HTMLParagraphElement>> = memo(
  ({ children, loading, showIfChildrenExists, ...props }) => {
    if (showIfChildrenExists && children) {
      return <p {...props}>{children}</p>;
    } else if (loading) {
      return <SkeletonText />;
    } else {
      return <p {...props}>{children}</p>;
    }
  }
);

export const P = Text;

export const H4: FC<TextProps<HTMLHeadingElement>> = memo(
  ({ children, loading, showIfChildrenExists, ...props }) => {
    loading ? <SkeletonText /> : <h4 {...props}>{children}</h4>;

    if (showIfChildrenExists && children) {
      return <h4 {...props}>{children}</h4>;
    } else if (loading) {
      return <SkeletonText />;
    } else {
      return <h4 {...props}>{children}</h4>;
    }
  }
);
