import React, { FC, useMemo } from 'react';
import {
  Tile,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableHeader,
  TableCell,
} from 'carbon-components-react';
import { P } from 'src/components';

interface Dimension {
  name: string;
  value: string;
}

interface DimensionsTileProps {
  dimensions?: Dimension[];
  loading?: boolean;
}

const dimensionHeaders = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'value',
    header: 'Value',
  },
];

export const DimensionsTile: FC<DimensionsTileProps> = ({ dimensions, loading }) => {
  const rowInput = useMemo(
    () => (dimensions ?? [])?.map((d) => ({ ...d, id: d.name })).filter((x) => x.value),
    [dimensions]
  );
  return (
    <Tile>
      <h3>Dimensions</h3>
      <DataTable rows={rowInput} headers={dimensionHeaders} size="xs" isSortable useZebraStyles>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }: any) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header: any) => (
                  <TableHeader {...getHeaderProps({ header })}>
                    <h5>{header.header}</h5>
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow {...getRowProps({ row })}>
                  {row.cells.map((cell: any) => (
                    <TableCell key={cell.id}>
                      <P loading={loading} showIfChildrenExists>
                        {cell.value}
                      </P>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DataTable>
    </Tile>
  );
};
