import { FC } from 'react';
import { Tile, Grid, Row, Column, Button, TextInput, Form } from 'carbon-components-react';
import { MadeWithLove, Version, Copyright } from 'src/components';

import './login.scss';
import logoDark from './logo-dark.png';
import logoLight from './logo-light.png';
import { useAuthentication, useFormInputInvalidProps } from './_utils';
import { useThemed } from 'src/utilities';

interface Inputs {
  clientId: string | null;
}

export const LoginScreen: FC = () => {
  const logo = useThemed({ light: logoLight, dark: logoDark });
  const { onSubmit, formState, register } = useAuthentication();
  const clientIdInvalidProps = useFormInputInvalidProps<Inputs>(formState, 'clientId');

  return (
    <>
      <Grid className="container">
        <Row className="top-row" />
        <Row className="body-row">
          <Column sm={0} md={1} lg={5} />
          <Column>
            <Tile className="main-tile">
              <img src={logo} height={100} width={200} className="logo" alt="Main Logo" />
              <Form onSubmit={onSubmit}>
                <TextInput
                  inline
                  id="client-id"
                  labelText="Client ID"
                  {...register('clientId')}
                  {...clientIdInvalidProps}
                />
                <Button size="sm" kind="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Tile>
          </Column>
          <Column sm={0} md={1} lg={5} />
        </Row>
        <Row className="bottom-row" />
        <Row className="footer-row">
          <Column sm={0} md={1} lg={4} />
          <Column>
            <Row>
              <Column>
                <Version />
              </Column>
              <Column>
                <MadeWithLove />
              </Column>
              <Column>
                <Copyright />
              </Column>
            </Row>
          </Column>
          <Column sm={0} md={1} lg={4} />
        </Row>
      </Grid>
    </>
  );
};
