import React, { FC } from 'react';
import { Image } from 'src/components';

interface ItemPrimaryImageTileProps {
  imageUrl: string | null;
}

export const ItemPrimaryImageTile: FC<ItemPrimaryImageTileProps> = ({ imageUrl }) => {
  return (
    <div className="item-primary-image-tile">
      <Image src={imageUrl} alt="selected main" />
    </div>
  );
};
