import { configureStore } from '@reduxjs/toolkit';
import { persistStore as configurePersistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { appSliceReducer } from './slices/app';
import { pick } from 'lodash/fp';

const userSessionSubset = pick(['clientId', 'expires']);

export const reduxStore = configureStore({
  reducer: {
    app: appSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  enhancers(defaultEnhancers) {
    return [
      Sentry.createReduxEnhancer({
        actionTransformer(action) {
          if (action.type === 'persist/REHYDRATE' && action.key === 'app') {
            return null;
          } else if (action.type === 'app/setAuth') {
            return {
              ...action,
              payload: typeof action.payload == 'object' ? userSessionSubset(action.payload) : '',
            };
          }
          return action;
        },
        stateTransformer(state) {
          return {
            ...state,
            app: userSessionSubset(state?.userSession),
          };
        },
      }),
      ...defaultEnhancers,
    ];
  },
});

export const persistStore = configurePersistStore(reduxStore);
