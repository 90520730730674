import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import _ from 'lodash';
import { get, pick, pipe } from 'lodash/fp';
import { appSliceActions, appStateSelectors, reduxStore } from '../redux';

const authRefreshTokenURI = Object.freeze('https://auth.yellowdogsoftware.com/refreshToken');

const transformRefreshResult = pipe(
  get('result'),
  pick(['accessToken', 'expires', 'refreshToken'])
);

function _refreshAuth(refreshToken: string, clientId: string) {
  return fetch(authRefreshTokenURI, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken, clientId }),
  })
    .then((resp) => resp.json())
    .then(transformRefreshResult);
}

const refreshAuth = async () => {
  // TO DO: get client id and refresh token from whereever it will be stored
  const { refreshToken, clientId } = appStateSelectors.getAppState(reduxStore.getState()) ?? {};

  if (refreshToken && clientId) {
    const data = await _refreshAuth(refreshToken, clientId);
    reduxStore.dispatch(appSliceActions.setAuth(data));
    return data.accessToken;
  }
  return _.stubString();
};

export const onErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors && operation.operationName !== 'login') {
    for (let err of graphQLErrors) {
      switch (err?.extensions?.code) {
        case 'UNAUTHENTICATED':
          return fromPromise(refreshAuth())
            .map((authorization) => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization,
                },
              });
              return operation;
            })
            .flatMap(forward);
        default:
          return forward(operation);
      }
    }
  }
  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
  return forward(operation);
});
