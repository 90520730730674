import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Row, Column, Tile, Tag, Modal, Tooltip } from 'carbon-components-react';
import { times } from 'lodash';
import { DimensionsTile } from './_dimensions';
import { MatrixTile } from './_matrix';
import { ItemPrimaryImageTile } from './_item-primary-image-tile';
import { DescriptionTile } from './_description-tile';
import { StoreOnHandsTile } from './store-onhands-tile';
import { useParams } from 'react-router';
import { SimpleInfoTile } from './_simple-info-tile';
import { ImagesTile } from './_images-tile';
import {
  useDingoGetItemDetails,
  UseDingoGetItemDetailsStoreLimit,
} from 'src/utilities/use-dingo-get-item-details';
import { Barcode } from 'src/components';
import { SKUTile } from './_sku-tile';
import { BarcodesTile } from './_barcodes-tile';

export const ItemDetailScreen: FC = () => {
  const { itemId } = useParams();
  const { data, variables, refetch, loading } = useDingoGetItemDetails(itemId as string);
  const variablesRef = useRef(variables);
  useEffect(() => {
    variablesRef.current = variables;
  }, [variables]);

  const item = useMemo(() => data?.me?.ydItemById ?? {}, [data]);
  const storeOnHands = useMemo(() => item?.stores?.nodes ?? [], [item]);
  const storeCount = useMemo(() => item?.stores?.count ?? 0, [item]);

  const levels = useMemo(() => times(4).map((i) => item?.[`level${i}`]?.description), [item]);

  const currency = useMemo(() => data?.currencyCode ?? 'USD', [data?.currencyCode]);

  const itemPrice = useMemo(
    () =>
      (item?.defaultRetailPrice as number)?.toLocaleString?.(
        navigator.languages ? navigator.languages[0] : navigator.language,
        {
          currency,
          style: 'currency',
        }
      ),
    [item?.defaultRetailPrice, currency]
  );

  const photos = useMemo(() => item?.photos?.map?.((x: any) => x?.url) ?? [], [item?.photos]);
  const matrixItems = useMemo(() => item?.matrix?.items, [item?.matrix]);
  const nextExpectedDelivery = useMemo(() => {
    return item?.nextExpectedDelivery
      ? new Date(item.nextExpectedDelivery).toLocaleDateString()
      : '--';
  }, [item?.nextExpectedDelivery]);

  const vendor = useMemo(() => data?.labels?.vendor ?? 'Vendor', [data?.labels]);
  const dimensions = useMemo(() => {
    if (loading && !data) {
      return undefined;
    }
    return times(10).map((i) => {
      const dim = `dimension${i + 1}`;
      return { name: data?.labels?.[dim], value: data?.me?.ydItemById?.[dim] };
    });
  }, [data, loading]);

  const onStoreOnHandsOffsetChange = useCallback(
    (storeOffset: number) => {
      refetch({
        ...variablesRef.current,
        storeOffset,
      });
    },
    [refetch]
  );

  const [mainImageURL, setMainImageURL] = useState<string | null>(item.primaryImageURL);
  useEffect(() => {
    setMainImageURL(item.primaryImageURL);
  }, [item.primaryImageURL]);

  const barcodes = useMemo(() => item.upcs?.filter?.((upc: string) => !isNaN(Number(upc))), [item]);
  const [barcode, setBarcode] = useState<null | string>(null);

  const closeModal = useCallback(() => setBarcode(null), [setBarcode]);

  return (
    <>
      <Modal open={!!barcode} passiveModal onRequestClose={closeModal} size="xs">
        <Barcode value={barcode ?? item.code} className="barcode rounded" />
      </Modal>
      <Grid className="container">
        <Row>
          <Column>
            <DescriptionTile
              code={item.code}
              description={item.description}
              dimension1={item.dimension1}
              dimension2={item.dimension2}
              price={item?.defaultRetailPrice}
              vendor={item.primaryVendor?.name}
              levels={levels}
              currencyCode={currency}
            />
          </Column>
        </Row>
        <Row>
          <Column lg={3}>
            <Row>
              <Column>
                <SKUTile sku={item.code} />
              </Column>
            </Row>
            <Row>
              <Column>
                <ItemPrimaryImageTile imageUrl={mainImageURL} />
              </Column>
            </Row>
            <Row>
              <Column>
                <ImagesTile imageURLs={photos} onImageClick={setMainImageURL} />
              </Column>
            </Row>
            <Row>
              <Column>
                <DimensionsTile dimensions={dimensions} loading={loading} />
              </Column>
            </Row>
            <Row>
              <Column>
                <BarcodesTile barcodes={barcodes} onBarcodeClick={setBarcode} />
              </Column>
            </Row>
          </Column>
          <Column>
            <Row>
              <Column lg={2}>
                <SimpleInfoTile title="Default Price" subtitle={itemPrice} loading={loading} />
              </Column>
              <Column lg={4}>
                <SimpleInfoTile
                  title={vendor}
                  subtitle={item.primaryVendor?.name}
                  loading={loading}
                />
              </Column>
              <Column>
                <SimpleInfoTile
                  title="Total On Hand"
                  subtitle={item?.totalOnHand}
                  loading={loading}
                />
              </Column>
              <Column>
                <SimpleInfoTile title="On Order" subtitle={item?.totalOnOrder} loading={loading} />
              </Column>
              <Column>
                <SimpleInfoTile
                  title="Next Delivery"
                  subtitle={nextExpectedDelivery}
                  loading={loading}
                />
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <StoreOnHandsTile
                  storeLabel={data?.labels?.store}
                  storeOnHands={storeOnHands}
                  storeCount={storeCount}
                  limit={variables?.storeLimit ?? UseDingoGetItemDetailsStoreLimit}
                  offset={variables?.storeOffset}
                  onOffsetChange={onStoreOnHandsOffsetChange}
                  loading={loading}
                />
              </Column>
              <Column>
                <MatrixTile items={matrixItems} loading={loading} />
              </Column>
            </Row>
          </Column>
        </Row>
      </Grid>
    </>
  );
};
