import { useCallback, useEffect } from 'react';
import { apolloClient } from './apollo';
import { useSelector, useDispatch } from 'react-redux';
import { appSliceActions, appStateSelectors } from '.';
import { useLoginModal } from 'src/providers';

const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
let windowObjectReference: Window | null = null;
export const domain = process.env.REACT_APP_SHEPARD_URL ?? 'https://shepard.yellowdog.software';

export const useAuth = () => {
  const { clientId } = useSelector(appStateSelectors.getAppState);
  const isLoggedIn = useSelector(appStateSelectors.getIsLoggedIn);
  const dispatch = useDispatch();

  const { open, close } = useLoginModal();

  useEffect(() => {
    const listener = ({ data, origin }: MessageEvent<any>) => {
      if (origin === domain && data.__typename === 'AccessToken') {
        close();
        dispatch(appSliceActions.setAuth(data));
      }
    };
    window.addEventListener('message', listener, false);
    return () => {
      // remove any existing event listeners
      window.removeEventListener('message', listener);
    };
  }, [clientId, close, dispatch]);

  const login = useCallback(
    (clientId: string) => {
      dispatch(appSliceActions.setClientId(clientId));
      const url = `${domain}?target=${window.location.href}&client_id=${clientId}`;
      if (process.env.REACT_APP_USE_MODAL_AUTH === 'true') {
        open(url);
      } else {
        if (windowObjectReference === null || windowObjectReference.closed) {
          /* if the pointer to the window object in memory does not exist
	  or if such pointer exists but the window was closed */
          windowObjectReference = window.open(url, window.location.href, strWindowFeatures);
        } else {
          /* else the window reference must exist and the window
	  is not closed; therefore, we can bring it back on top of any other
	  window with the focus() method. There would be no need to re-create
	  the window or to reload the referenced resource. */
          windowObjectReference.focus();
        }
      }
    },
    [dispatch, open]
  );

  const logout = useCallback(async () => {
    await apolloClient.clearStore();
    dispatch(appSliceActions.logOut());
  }, [dispatch]);

  return { login, logout, isLoggedIn, clientId };
};
