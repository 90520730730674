import React, { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { Form, Search, SearchProps } from 'carbon-components-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

type FormValues = {
  searchText: string;
};

export const SearchBar: FC<SearchProps> = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = useMemo(() => searchParams.get('searchtext') ?? undefined, [searchParams]);

  const { handleSubmit, register, setValue, reset } = useForm<FormValues>({
    defaultValues: {
      searchText,
    },
  });
  const onValid: SubmitHandler<FormValues> = useCallback(
    ({ searchText }) => {
      const queryString = searchText ? `?searchtext=${searchText}` : '';
      navigate(`/search${queryString}`);
    },
    [navigate]
  );
  const { onChange, ...rest } = useMemo(() => register('searchText'), [register]);
  const onChangeCallback: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setValue('searchText', e.target.value);
    },
    [setValue]
  );

  const onClearCallback = useCallback(() => {
    reset();
    setSearchParams({});
  }, [reset, setSearchParams]);

  return (
    <Form onSubmit={handleSubmit(onValid)}>
      <Search {...props} onChange={onChangeCallback} onClear={onClearCallback} {...rest} />
    </Form>
  );
};
