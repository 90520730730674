import React, { FC, useMemo } from 'react';
import { Tile } from 'carbon-components-react';

interface DescriptionTileProps {
  code: string;
  description: string;
  dimension1: string;
  dimension2: string;
  vendor: string;
  price: number;
  currencyCode?: string;
  levels: string[];
}

export const DescriptionTile: FC<DescriptionTileProps> = ({
  code,
  description,
  dimension1,
  dimension2,
  vendor,
  price,
  levels,
  currencyCode,
}) => {
  const title = useMemo(
    () =>
      [
        code,
        description,
        dimension1,
        dimension2,
        vendor,
        price?.toLocaleString?.('en-us', { style: 'currency', currency: currencyCode }),
      ].join(' - '),
    [code, description, dimension1, dimension2, vendor, price, currencyCode]
  );
  const subTitle = useMemo(() => levels.filter(Boolean).join(' / '), [levels]);
  return (
    <Tile className="description-tile">
      <h3>{title}</h3>
      <p>{subTitle}</p>
    </Tile>
  );
};
