import React, { FC, useCallback, useMemo } from 'react';
import {
  Tile,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableHeader,
  TableCell,
  DataTableHeader,
  PaginationNav,
} from 'carbon-components-react';
import * as _ from 'lodash';
import { Text } from 'src/components';

interface Store {
  id: string;
  description: string;
  code: string;
}

interface StoreOnHand {
  onHand?: number | null;
  store: Store;
  bin?: string | null;
}

interface StoreOnHandsTileProp {
  storeOnHands: StoreOnHand[];
  storeLabel?: string;
  storeCount: number;
  limit: number;
  offset?: number;
  onOffsetChange: (offset: number) => void;
  loading?: boolean;
}

const initialHeaders: DataTableHeader[] = [
  {
    key: 'bin',
    header: 'Bin',
  },
  {
    key: 'onHand',
    header: 'OnHand',
  },
];

export const StoreOnHandsTile: FC<StoreOnHandsTileProp> = ({
  storeOnHands,
  storeCount,
  storeLabel,
  limit,
  offset,
  onOffsetChange,
  loading,
}) => {
  const totalItems = useMemo(() => Math.ceil(storeCount / (limit || 1)), [storeCount, limit]);
  const currentPage = useMemo(() => Math.ceil((offset ?? 0) / (limit || 1)), [limit, offset]);
  const rows = useMemo(() => {
    return loading
      ? _.range(limit).map((_x, i) => ({ description: '', id: String(i), onHand: 0, bin: null }))
      : storeOnHands.map(({ store: { description, id }, onHand, bin }) => ({
          description,
          id,
          onHand,
          bin,
        }));
  }, [storeOnHands, loading, limit]);

  const onChangeCallback = useCallback(
    (page) => {
      onOffsetChange(limit * page);
    },
    [onOffsetChange, limit]
  );

  const label = useMemo(() => storeLabel ?? 'Store', [storeLabel]);

  const headers = useMemo(() => {
    return [
      {
        key: 'description',
        header: label,
      },
      ...initialHeaders,
    ];
  }, [label]);

  return (
    <Tile>
      <h3>{label} OnHands</h3>
      <DataTable rows={rows} headers={headers} size="xs" useZebraStyles overflowMenuOnHover>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }: any) => (
          <Table {...getTableProps()} className="fixed-table">
            <TableHead>
              <TableRow>
                {_.take(headers, 1).map((header: any) => (
                  <TableHeader {...getHeaderProps({ header })} className="description-col">
                    {header.header}
                  </TableHeader>
                ))}

                {_.tail(headers).map((header: any) => (
                  <TableHeader {...getHeaderProps({ header })} align="right">
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow {...getRowProps({ row })}>
                  {_.take(row.cells, 1).map((cell: any) => (
                    <TableCell key={cell.id} align="left">
                      <Text loading={loading} className="one-line">
                        {cell.value}
                      </Text>
                    </TableCell>
                  ))}
                  {_.tail(row.cells).map((cell: any) => (
                    <TableCell key={cell.id} align="right">
                      <Text loading={loading} className="one-line">
                        {cell.value}
                      </Text>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DataTable>
      <PaginationNav
        loop
        className="pagination-nav"
        page={currentPage}
        totalItems={totalItems}
        itemsShown={5}
        onChange={onChangeCallback}
      />
    </Tile>
  );
};
