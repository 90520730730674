import { ApolloLink } from '@apollo/client';
import { appStateSelectors, reduxStore } from 'src/utilities/redux';

// TO DO: add `authorization`
export const addHeadersLink = new ApolloLink((operation, forward) => {
  const authorization = appStateSelectors.getAppState(reduxStore.getState())?.accessToken;
  operation.setContext({
    headers: {
      authorization,
      'X-GraphQL-Deduplicate': true,
    },
  });
  return forward(operation);
});
