import { TextInputProps } from 'carbon-components-react';
import { get } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { FormState, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/utilities';

interface Inputs {
  clientId: string | null;
}

export const useAuthentication = () => {
  const { login, isLoggedIn, clientId, logout } = useAuth();
  const { handleSubmit, register, formState } = useForm<Inputs>({
    defaultValues: {
      clientId,
    },
  });
  const navigate = useNavigate();

  const onSubmitCallback: SubmitHandler<Inputs> = useCallback(
    ({ clientId }) => {
      if (clientId) {
        login(clientId);
      } else {
        logout();
      }
    },
    [logout, login]
  );

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/search');
    }
  }, [isLoggedIn, navigate]);

  const onSubmit = useMemo(() => handleSubmit(onSubmitCallback), [handleSubmit, onSubmitCallback]);

  return { onSubmit, formState, register };
};

export const useFormInputInvalidProps = <T extends any>(
  { errors, isDirty }: FormState<T>,
  key: keyof T
): Pick<TextInputProps, 'invalid' | 'invalidText'> => {
  return useMemo(() => {
    const invalidText = get(errors, `${key}.message`);
    return { invalid: isDirty && !!invalidText, invalidText };
  }, [errors, isDirty, key]);
};
