import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createCompressor from 'redux-persist-transform-compress';

type NullableString = string | null;

export interface AppState {
  clientId: NullableString;
  accessToken: NullableString;
  refreshToken: NullableString;
  expires: Date | null;
}

interface AuthPayload {
  expires: Date | null;
  accessToken: string;
  refreshToken: string;
}

const initialState: AppState = {
  clientId: null,
  accessToken: null,
  refreshToken: null,
  expires: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setClientId: (state, action: PayloadAction<string>) => {
      state.clientId = action.payload;
    },
    logOut: (state) => {
      state.accessToken = null;
      state.expires = null;
      state.refreshToken = null;
    },
    setAuth: (state, action: PayloadAction<AuthPayload>) => {
      const { accessToken, refreshToken, expires } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.expires = expires;
    },
  },
});

export const appSliceActions = appSlice.actions;
export const appSliceReducer = persistReducer<AppState>(
  {
    storage,
    timeout: 0,
    blacklist: [],
    key: 'app',
    debug: true,
    transforms: [createCompressor()],
  },
  appSlice.reducer
);
// export const appSliceReducer = appSlice.reducer;
