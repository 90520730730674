import { useMemo } from 'react';
import './items.scss';
import { DataTableHeader } from 'carbon-components-react';

const initialHeaders: DataTableHeader[] = [
  {
    key: 'primaryImageURL',
    header: '',
  },
  {
    key: 'description',
    header: 'Item',
  },
];

const defaultHeaders: DataTableHeader[] = [
  ...initialHeaders,
  {
    key: 'dimension1',
    header: 'Size',
  },
  {
    key: 'dimension2',
    header: 'Color',
  },
  {
    key: 'dimension3',
    header: 'Dim3',
  },
  {
    key: 'code',
    header: 'SKU',
  },
  {
    key: 'totalOnHand',
    header: 'On-Hand (total)',
  },
  {
    key: 'vendor',
    header: 'Vendor',
  },
];

interface Labels {
  dimension1?: string | null;
  dimension2?: string | null;
  dimension3?: string | null;
  vendor?: string | null;
}

export const useHeaderLabels = (labels?: Labels) => {
  return useMemo(
    () =>
      labels
        ? [
            ...initialHeaders,
            {
              key: 'dimension1',
              header: labels?.dimension1,
            },
            {
              key: 'dimension2',
              header: labels?.dimension2,
            },
            {
              key: 'dimension3',
              header: labels?.dimension3,
            },
            {
              key: 'code',
              header: 'SKU',
            },
            {
              key: 'totalOnHand',
              header: 'On-Hand (total)',
            },
            {
              key: 'vendor',
              header: labels?.vendor,
            },
          ]
        : defaultHeaders,
    [labels]
  ) as DataTableHeader[];
};
