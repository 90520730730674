import { useEffect, useState } from 'react';
import { batch } from 'react-redux';

enum Routes {
  availability = 'availability.json',
  helpLinks = 'help-links.json',
  contactInfo = 'contact-info.json',
}
const url = 'https://yellowdogsoftware.github.io/yd-static-data/';

interface HelpLink {
  key: string;
  value: string[];
}

export const useHelpLinks = () => {
  const [helpLinks, setHelpLinks] = useState<HelpLink[]>([]);
  const [availability, setAvailability] = useState<HelpLink[]>([]);
  const [contactInfo, setContactInfo] = useState<HelpLink[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    (async () => {
      const [helpLinks, availability, contactInfo] = await Promise.all([
        fetch(`${url}${Routes.helpLinks}`, { signal }).then((resp) => resp.json()),
        fetch(`${url}${Routes.availability}`, { signal }).then((resp) => resp.json()),
        fetch(`${url}${Routes.contactInfo}`, { signal }).then((resp) => resp.json()),
      ]);
      batch(() => {
        setHelpLinks(helpLinks);
        setAvailability(availability);
        setContactInfo(contactInfo);
        setLoading(false);
      });
    })();
    return () => {
      controller.abort();
    };
  }, []);

  return { helpLinks, availability, contactInfo, loading };
};
