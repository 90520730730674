import React, { FC, HTMLProps, ReactEventHandler, useCallback, useEffect, useState } from 'react';

import { toDog } from './_utils';

interface ImageProps extends Omit<HTMLProps<HTMLImageElement>, 'src'> {
  src?: string | null;
}

export const Image: FC<ImageProps> = ({ src, onError, ...rest }) => {
  const [sauce, setSauce] = useState(src);

  useEffect(() => {
    setSauce(src ?? toDog(src));
  }, [src, setSauce]);

  const onErrorCallback: ReactEventHandler<HTMLImageElement> = useCallback(
    (e) => {
      const target: any = e?.target;
      if (target?.onerror) {
        target.onerror = null;
      }
      onError?.(e);
      setSauce(toDog(src));
    },
    [onError, src, setSauce]
  );
  return <img src={sauce} onError={onErrorCallback} {...(rest as any)} />;
};
