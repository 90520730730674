import { ChevronRight20 } from '@carbon/icons-react';
import { TableCell, TableRow, TableRowProps, Tag } from 'carbon-components-react';
import React, { FC, useCallback } from 'react';

import { Image, P } from 'src/components';

interface Item {
  id: string;
  description: string | null;
  code: string | null;
  dimension1: string | null;
  dimension2: string | null;
  dimension3: string | null;
  primaryImageURL?: string | null;
  totalOnHand: number;
  primaryVendor?: {
    name: string;
  };
}

interface ItemTableRowProps extends Omit<TableRowProps, 'onClick'> {
  onClick: (id: string) => void;
  loading?: boolean;
  id: string;
  small: boolean;
  item: Item;
}

export const ItemTableRow: FC<ItemTableRowProps> = ({ onClick, loading, item, small, ...rest }) => {
  const onClickCallback = useCallback(() => onClick(item.id), [item.id, onClick]);
  return small ? (
    <TableRow {...rest} onClick={onClickCallback}>
      <TableCell width={10}>
        <Image
          src={item.primaryImageURL}
          alt="item image"
          height={40}
          width={40}
          className="rounded"
        />
      </TableCell>
      <TableCell>
        <div>
          {item?.description && <p>{item.description}</p>}
          <div>
            {item?.code && <Tag type="green">{item.code}</Tag>}
            {item?.dimension1 && <Tag type="blue">{item.dimension1}</Tag>}
            {item?.primaryVendor?.name && <Tag type="cool-gray">{item.primaryVendor.name}</Tag>}
            {item?.dimension2 && <Tag type="purple">{item.dimension2}</Tag>}
            {item?.dimension3 && <Tag type="teal">{item.dimension3}</Tag>}
            {item?.totalOnHand && <Tag type="red">{item.totalOnHand}</Tag>}
          </div>
        </div>
      </TableCell>
      <TableCell align="right">
        <ChevronRight20 />
      </TableCell>
    </TableRow>
  ) : (
    <TableRow {...rest} onClick={onClickCallback}>
      <TableCell width={20}>
        <Image
          src={item.primaryImageURL}
          alt="item item"
          height={20}
          width={20}
          className="rounded"
        />
      </TableCell>
      <TableCell align="left">
        <P loading={loading}>{item?.description}</P>
      </TableCell>
      <TableCell align="left">
        <P loading={loading}>{item?.dimension1}</P>
      </TableCell>
      <TableCell align="left">
        <P loading={loading}>{item?.dimension2}</P>
      </TableCell>
      <TableCell align="left">
        <P loading={loading}>{item?.dimension3}</P>
      </TableCell>
      <TableCell align="left">
        <P loading={loading}>{item?.code}</P>
      </TableCell>
      <TableCell align="left">
        <P loading={loading}>{item?.totalOnHand}</P>
      </TableCell>
      <TableCell align="left">
        <P loading={loading}>{item?.primaryVendor?.name}</P>
      </TableCell>
      <TableCell align="right">
        <ChevronRight20 />
      </TableCell>
    </TableRow>
  );
};
