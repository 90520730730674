import React, { FC, CSSProperties } from 'react';
import { Tile } from 'carbon-components-react';
import { Barcode } from 'src/components';

interface SKUTileProps {
  className?: string;
  style?: CSSProperties;
  sku: string;
}

export const SKUTile: FC<SKUTileProps> = ({ className = 'sku-tile', style, sku }) => {
  return (
    <Tile className={className} style={style}>
      <h3>SKU</h3>
      <Barcode value={sku} className="barcode rounded" />
    </Tile>
  );
};
