import React from 'react';
import './routes.scss';
import { ItemDetailScreen } from './item-detail';
import { ItemsScreen } from './items';
import { LoginScreen } from './login';
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom';
import { Content } from 'carbon-components-react';
import { AppHeader } from 'src/containers';

export const Routes = () => {
  return (
    <Router>
      <AppHeader />
      <Content>
        <Switch>
          <Route path="/" element={<ItemsScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/search" element={<ItemsScreen />} />
          <Route path="/items" element={<ItemsScreen />} />
          <Route path="/items/:itemId" element={<ItemDetailScreen />} />
        </Switch>
      </Content>
    </Router>
  );
};
