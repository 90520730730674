import React, { FC, useCallback } from 'react';
import { Tile } from 'carbon-components-react';
import { Image as Img } from 'src/components';

interface ImagesTileProps {
  imageURLs: string[];
  onImageClick?: (imgSrc: string) => void;
}

interface ImageProps {
  src: string;
  alt?: string;
  onImageClick?: (imgSrc: string) => void;
}

const Image: FC<ImageProps> = ({ src, onImageClick, alt }) => {
  const onClick = useCallback(() => {
    onImageClick?.(src);
  }, [src, onImageClick]);
  return (
    <Img
      height={75}
      width={75}
      src={src}
      className="image-thumb rounded"
      onClick={onClick}
      alt={alt}
    />
  );
};

export const ImagesTile: FC<ImagesTileProps> = ({ imageURLs, onImageClick }) => {
  return (
    <Tile className="images-tile">
      {imageURLs.map((src) => (
        <Image key={src} src={src} onImageClick={onImageClick} alt={src} />
      ))}
    </Tile>
  );
};
