import React from 'react';
import * as Sentry from '@sentry/react';
import { Routes } from './routes';
import { ApolloProvider, LoginModalProvider, ReduxProvider } from './providers';
import './App.scss';

function App() {
  return (
    <div className="App">
      <ReduxProvider>
        <ApolloProvider>
          <LoginModalProvider>
            <Routes />
          </LoginModalProvider>
        </ApolloProvider>
      </ReduxProvider>
    </div>
  );
}

export default Sentry.withProfiler(App);
