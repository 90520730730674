import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import './items.scss';
import {
  DataTableHeader,
  PaginationNav,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'carbon-components-react';
import { useDingoGetItems, useMediaQuery } from 'src/utilities';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useHeaderLabels } from './_utils';
import { ItemTableRow } from './_table-row';

interface Item {
  id: string;
  description: string;
  code: string;
  dimension1?: string | null;
  dimension2?: string | null;
  primaryImageURL?: string | null;
}

interface YdItems {
  nodes: Item[];
  count: number;
}

export const ItemsScreen: FC = () => {
  const [searchParams] = useSearchParams();
  const searchText = useMemo(() => searchParams.get('searchtext') ?? undefined, [searchParams]);
  const { data, variables, refetch, loading } = useDingoGetItems(searchText);
  const variablesRef = useRef(variables);

  useEffect(() => {
    variablesRef.current = variables;
  }, [variables]);

  const onPaginationChangeCallback = useCallback(
    (x) => {
      refetch({ ...variablesRef.current, offset: (variablesRef.current?.limit ?? 0) * x });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [refetch]
  );

  const { nodes, count } = (data?.me?.ydItems ?? {}) as YdItems;
  const totalItems = useMemo(
    () => Math.ceil((count || 0) / (variablesRef.current?.limit || 1)),
    [count]
  );

  const currentPage = useMemo(
    () => Math.ceil((variables?.offset ?? 0) / (variables?.limit ?? 1)),
    [variables]
  );

  const headers: DataTableHeader[] = useHeaderLabels(data?.labels);

  const navigate = useNavigate();
  const onRowClick = useCallback((id: string) => navigate(`/items/${id}`), [navigate]);

  const { small } = useMediaQuery();
  const itemsShown = useMemo(() => (small ? 6 : 10), [small]);

  return (
    <div>
      <Table size="md" useZebraStyles overflowMenuOnHover>
        {!small && (
          <TableHead>
            <TableRow>
              {headers.map((header: any) => (
                <TableHeader key={header.header}>{header.header}</TableHeader>
              ))}
              <TableHeader className="image-col"></TableHeader>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {nodes?.map?.((item: any) => (
            <ItemTableRow
              id={item.id}
              key={item.id}
              small={small}
              item={item}
              onClick={onRowClick}
              loading={loading}
            />
          ))}
        </TableBody>
      </Table>
      <PaginationNav
        loop
        page={currentPage}
        totalItems={totalItems}
        itemsShown={itemsShown}
        onChange={onPaginationChangeCallback}
      />
    </div>
  );
};
