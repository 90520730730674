import { sample, memoize } from 'lodash';

import boxerImg from './_boxer.png';
import goldenRetrieverImg from './_golden-retriever.png';
import terrierImg from './_terrier.png';

const images = [boxerImg, goldenRetrieverImg, terrierImg];
const getRandomDogImg = () => sample(images) as string;

/**
 * boils down a string to an index of array housing the local image URLs
 */
const toDogMem = memoize((str: string) => {
  let hash = 0;
  for (const x of str) {
    hash = x.charCodeAt(0) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % images.length) + images.length) % images.length;
  return images[hash];
});

/**
 *
 * @param str some string — doesn't really matter
 * @returns
 */
export const toDog = (str?: string | null) => {
  if (!str?.length) {
    return getRandomDogImg();
  }
  return toDogMem(str);
};
